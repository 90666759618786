import React, { useEffect, useState, useffect } from "react";
import { FooterBig, Header } from "../components";
import {
  errorMsg,
  getArray,
  handleScrollError,
  images,
} from "../actions/customFn";
import { FormHelperText, TextField } from "@mui/material";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useRef } from "react";

import { motion } from "framer-motion";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import SmallLoader from "react-js-loader";
// import { Input } from "@mui/material";
import {
  addCourse,
  asyncdevicecourseInculde,
  asyncgetcourseCategory,
  getCourseDetail,
} from "../actions/courseAction";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "../hooks/Loader";
import { reactIcons } from "../utils/icons";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const UploadMycourse = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const { id } = useParams();

  // const handleClick = () => {
  //   inputRef.current.click();
  // };
  const [editorType, setEditorType] = useState("");

  const [buttonloader, setButtonLoader] = useState(false);

  const [formErrors, setFormError] = useState({});
  const [courseForm, setCourseForm] = useState({
    tag_title: "",
    title: "",
    description: "",
    price: "",
    sort_description: "",
    course_category: "",
    courseduration: "",
    image: "",
    add_what_you_learn: ["", "", "", ""],

    access_on_device: Array(),

    playlist_title: [""],
    playlist_description: [""],
    playlist_image: [""],
    // courseimage: {},
    course_image: [""],
    course_imgtitle: [""],
  });

  // ----------- course include device dropdown -----------
  const [coursedevice, setCourseDevice] = useState();
  // ----------- course include device dropdown -----------
  const [coursecategory, setCourseCategory] = useState();

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    let playlist_title = courseForm.playlist_title;
    let playlist_description = courseForm.playlist_description;
    let playlist_image = courseForm.playlist_image;

    playlist_title.splice(index, 1);
    playlist_description.splice(index, 1);
    playlist_image.splice(index, 1);

    setCourseForm((prev) => ({
      ...prev,
      playlist_title: playlist_title,
      playlist_description: playlist_description,
      playlist_image: playlist_image,
    }));
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setCourseForm((prev) => ({
      ...prev,
      playlist_title: [...prev.playlist_title, ""],
      playlist_description: [...prev.playlist_description, ""],
      playlist_image: [...prev.playlist_image, ""],
    }));
  };

  // ---------- add course image function --------
  const handleRemoveImageCourse = (index) => {
    let course_imgtitle = courseForm.course_imgtitle;
    let course_image = courseForm.course_image;

    course_imgtitle.splice(index, 1);
    course_image.splice(index, 1);

    setCourseForm((prev) => ({
      ...prev,
      course_imgtitle: course_imgtitle,
      course_image: course_image,
    }));
  };

  const handleAddCourseImage = () => {
    setCourseForm((prev) => ({
      ...prev,
      course_imgtitle: [...prev.course_imgtitle, ""],
      course_image: [...prev.course_image, ""],
    }));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (e, type) => {
    if (e.target != undefined) {
      const { name, value, id, checked } = e.target;

      if (name == "playlist_title" || name == "course_imgtitle") {
        let newState = [...courseForm[name]];

        if (newState[type] != undefined) {
          newState[type] = value;
          // newState[type].push(value);
        }
        setCourseForm((formData) => ({ ...formData, [name]: newState }));
      } else if (name == "playlist_image" || name == "course_image") {
        //  let images = {...courseForm[name]};

        let newState = [...courseForm[name]];
        if (newState[type] != undefined) {
          newState[type] = e.target.files[0];
        }

        setCourseForm((formData) => ({ ...formData, [name]: newState }));
      } else {
        if (name == "add_what_you_learn") {
          let newState = [...courseForm[name]];
          if (newState[id] != undefined) {
            newState[id] = value;
          }
          setCourseForm((formData) => ({ ...formData, [name]: newState }));
        } else {
          if (name == "image") {
            setCourseForm((formData) => ({
              ...formData,
              [name]: e.target.files[0],
            }));
          } else {
            setCourseForm((formData) => ({ ...formData, [name]: value }));
          }
        }
      }
    } else {
      if (editorType != "") {
        if (editorType == "playlist_description") {
          // console.log("value=", editorType);
          let newState = [...courseForm[editorType]];
          if (newState[type] != undefined) {
            newState[type] = e;
            setCourseForm((formData) => ({
              ...formData,
              [editorType]: newState,
            }));
          }
        } else {
          setCourseForm((formData) => ({ ...formData, [editorType]: e }));
        }
      }
    }
  };

  const handleChecked = (e, ids) => {
    const { name, value, id, checked } = e.target;
    let newState = [...courseForm["access_on_device"]];
    if (checked != undefined) {
      if (checked) {
        if (courseForm.access_on_device.includes(ids) == false) {
          courseForm.access_on_device.push(ids);
        }
      } else if (checked == false) {
        var ind = courseForm.access_on_device.indexOf(ids);

        if (courseForm.access_on_device.includes(ids) == true) {
          courseForm.access_on_device.splice(ind, 1);
          // delete courseForm.access_on_device[ind]
        }
      }
      // console.log("one==",courseForm.access_on_device);
    }
  };

  const errors = {};
  const validateFormData = async () => {
    try {
      if (courseForm.tag_title == "") {
        errors.tag_title = "Enter Tag Title";
      }
      if (courseForm.title == "") {
        errors.title = "Enter Title";
      }
      if (courseForm.image == "") {
        errors.image = "Choose Image";
      }
      if (courseForm.sort_description == "") {
        errors.sort_description = "Enter Sort description";
      }

      if (courseForm.price == "") {
        errors.price = "Enter Price";
      }
      if (courseForm.description == "") {
        errors.description = "Enter Description";
      }

      if (courseForm.course_category == "") {
        errors.course_category = "Choose course category";
      }

      if (courseForm.playlist_image == "") {
        errors.playlist_image = "Field is Required";
      }
      if (courseForm.playlist_description == "") {
        errors.playlist_description = "Field is Required";
      }
      if (courseForm.playlist_title == "") {
        errors.playlist_title = "Field is Required";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  //Add Course Api Start
  const handleAddCourse = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        addCourse(courseForm, navigate, setButtonLoader);
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };
  //Add Course Api End

  // console.log("courseFormes=", courseForm);
  // ----------- course include device dropdown -----------
  useEffect(() => {
    asyncdevicecourseInculde(setCourseDevice);
    asyncgetcourseCategory(setCourseCategory);
  }, []);

  // ------------- show detail of course to edit -----------
  const [isLoader, setIsLoader] = useState(false);
  const [courseDetail, setCourseDetail] = useState("");

  // console.log("jj==",id);
  useEffect(() => {
    if (id != "" && id != undefined) {
      setIsLoader(true);
      getCourseDetail(id, setCourseDetail, setIsLoader);
    }
  }, [id]);
  // console.log("courseDetail#=",courseDetail);
  // console.log("courseDetail####====",courseForm);

  useEffect(() => {
    if (id != "" && courseDetail != undefined && courseDetail != "") {
      setCourseForm({
        ...courseForm,

        tag_title: courseDetail.course_details.tag_title,
        title: courseDetail.course_details.title,
        description: courseDetail.course_details.description,
        price: courseDetail.course_details.price,
        sort_description: courseDetail.course_details.sort_description,
        course_category: courseDetail.course_details.category_id,
        courseduration: courseDetail.course_details.course_duration,
        image: courseDetail.course_details.image,
        // add_what_you_learn: [ courseDetail.course_details.add_what_you_learn ],
        add_what_you_learn: getArray(
          courseDetail.course_details.add_what_you_learn,
          "title"
        ),

        playlist_title: getArray(
          courseDetail.course_details.course_playlist,
          "playlist_title"
        ),
        playlist_description: getArray(
          courseDetail.course_details.course_playlist,
          "playlist_description"
        ),
        playlist_image: getArray(
          courseDetail.course_details.course_playlist,
          "image"
        ),

        course_image: getArray(
          courseDetail.course_details.course_media,
          "image"
        ),
        course_imgtitle: getArray(
          courseDetail.course_details.course_media,
          "description"
        ),

        access_on_device: getArray(
          courseDetail.course_details.course_includes,
          "id"
        ),
      });
    }
  }, [courseDetail]);

  // console.log("access_on_device=", courseForm.access_on_device);

  // ------------- update product ------------
  const handleupdate = (e, cors_id) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        addCourse(courseForm, navigate, setButtonLoader, cors_id);
        // asyncAddProduct(formData, {navigate,setButtonLoader}, prd_id);
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg addprodform upload-course-page">
        <div className="container-fluid">
          <Header />
          <div className="page-all-data">
            {isLoader === true ? (
              <div className="">
                <Loader />
              </div>
            ) : (
              <div className="add-product-main">
                <div className="add_product_textfild ">
                  <div className="row align-content-center">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 d-flex align-items-center">
                      <div className="heading-group">
                        <div className="back_btn">
                          <button onClick={() => navigate("/create-course")}>
                            {reactIcons.arrowLeft}
                          </button>
                        </div>
                        <div className="new_prd_heading">
                          {id ? (
                            <h1>Edit Your Course</h1>
                          ) : (
                            <h1>Upload New Course</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="uplod_course">
                    <h4>Courses Details</h4>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        Title
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        name="title"
                        className="input_username new-edit-main-all"
                        id="outlined-basic"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={courseForm.title}
                        error={formErrors.title}
                        helperText={formErrors.title}
                        placeholder="Enter your course title"
                      />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        Short description
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        className="input_username new-edit-main-all"
                        id="outlined-basic"
                        name="sort_description"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={courseForm.sort_description}
                        error={formErrors.sort_description}
                        helperText={formErrors.sort_description}
                        placeholder="Enter your sort description"
                      />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        Tag Title
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        className="input_username new-edit-main-all"
                        id="outlined-basic"
                        name="tag_title"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={courseForm.tag_title}
                        error={formErrors.tag_title}
                        helperText={formErrors.tag_title}
                        placeholder="Enter tag name"
                      />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 new-all-inputs mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Course Price
                          </FormHelperText>
                          <TextField
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: "100%",
                            }}
                            type="number"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            name="price"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={courseForm.price}
                            error={formErrors.price}
                            helperText={formErrors.price}
                            placeholder="Enter course price"
                          />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 new-all-inputs mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Course Category
                          </FormHelperText>
                          <Select
                            className="input_username new-edit-main-all w-100"
                            id="demo-simple-select-helper-label"
                            name="course_category"
                            // value={formData.category}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={courseForm.course_category}
                            MenuProps={MenuProps}
                            error={formErrors.course_category}
                            helperText={formErrors.course_category}
                          >
                            {coursecategory &&
                              coursecategory.length > 0 &&
                              coursecategory.map((value, index) => {
                                return (
                                  <MenuItem key={index} value={value.id}>
                                    {value.title}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        Upload Image and Video
                      </FormHelperText>
                      <div className="post_image_upload upload_courseimg">
                        {/* <img src={"/images/upload-post1.png"} alt="" /> */}

                        <div className="file_upload_product">
                          <input
                            id="fileInput"
                            name="image"
                            type="file"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="browser"
                            ref={inputRef}
                          />
                          {/* <h6>Upload Image and Video</h6> */}
                        </div>
                        {formErrors.image && (
                          <div className="invalid-msg">{formErrors.image}</div>
                        )}
                      </div>
                      {courseForm.image != undefined &&
                        courseForm.image != "" ? (
                        <>
                          {id ? (
                            <img
                              src={
                                (courseForm.image.name != undefined &&
                                  URL.createObjectURL(courseForm.image)) ||
                                courseForm.image
                              }
                              alt=""
                              style={{ height: "120px", width: "200px" }}
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(courseForm.image)}
                              alt=""
                              style={{ height: "120px", width: "200px" }}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {console.log("courseForm==", courseForm)}
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        Description
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        error={formErrors.description}
                        name="description"
                        className="input_username new-edit-main-all"
                        id="outlined-basic"
                        placeholder="description"
                        onChange={(e) => handleChange(e)}
                        multiline
                        value={courseForm.description}
                        rows={4}
                        helperText={formErrors.description}
                      />
                      {/* <div className="account_txteditor_main">
                        <h6>Description</h6>

                        <div
                          style={{
                            // display: "grid",
                            color: "#fff",
                            justifyContent: "center",
                          }}
                        >
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={courseForm.description}
                            placeholder=" "
                            onChange={(e) => handleChange(e)}
                            onFocus={(e) => {
                              setEditorType("description");
                            }}
                            style={{ height: "80px", color: "#fff" }}
                          ></ReactQuill>
                        </div>
                      </div>
                      {formErrors.description && (
                        <div className="invalid-msg">
                          {formErrors.description}
                        </div>
                      )} */}
                    </div>
                  </div>
                  {/* {console.log("courseForm.add_what_you_learn",courseForm)} */}
                  <div className="uplod_course">
                    <h4>What you'll learn Details</h4>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        1.Learn Point
                      </FormHelperText>

                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        className="input_username new-edit-main-all"
                        // id="outlined-basic"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        id="0"
                        value={courseForm.add_what_you_learn[0]}
                        name="add_what_you_learn"
                        placeholder="Enter your Point"
                      />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        2.Learn Point
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        id="1"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className="input_username new-edit-main-all"
                        // id="outlined-basic"
                        value={courseForm.add_what_you_learn[1]}
                        name="add_what_you_learn"
                        placeholder="Enter your Point"
                      />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        3.Learn Point
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        id="2"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={courseForm.add_what_you_learn[2]}
                        className="input_username new-edit-main-all"
                        name="add_what_you_learn"
                        placeholder="Enter your Point"
                      />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <FormHelperText
                        sx={{ color: "#fff", marginBottom: "5px" }}
                      >
                        4.Learn Point
                      </FormHelperText>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        id="3"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={courseForm.add_what_you_learn[3]}
                        className="input_username new-edit-main-all ccv_input"
                        // id="outlined-basic"
                        name="add_what_you_learn"
                        placeholder="Enter your Point"
                      />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      <div className="uplod_course">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Course Duration
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          value={courseForm.courseduration}
                          name="courseduration"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Enter your course duration"
                        />
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                      {coursedevice != undefined && coursedevice.length > 0 && (
                        <div className="course_checkbox">
                          <h4>Access on device</h4>
                          { }
                          <FormGroup className="nft-filter-sides">
                            {coursedevice.map((device, index) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        handleChecked(e, device.id);
                                      }}
                                      id={device.id}
                                      name={device.device_title}
                                      // value={device.id}
                                      defaultChecked={
                                        courseForm.access_on_device !=
                                        undefined &&
                                        courseForm.access_on_device.find(
                                          (obj) => obj === device.id
                                        )
                                      }

                                    // defaultChecked={()}
                                    // defaultChecked={device.id == courseForm.access_on_device}
                                    />
                                  }
                                  label={device.device_title}
                                />
                              );
                            })}
                          </FormGroup>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="uplod_course">
                    <h4>Playlist Course</h4>
                  </div>

                  {courseForm.playlist_title?.map((value, index) => {
                    return (
                      <>
                        <div className="course_bgplay_list">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Course Title
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                name="playlist_title"
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                                value={value}
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter your course title"
                                error={formErrors.playlist_title}
                                helperText={formErrors.playlist_title}
                              />
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Description
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                error={formErrors.playlist_description}
                                name="playlist_description"
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Info"
                                onChange={(e) => handleChange(e, index)}
                                multiline
                                rows={4}
                                value={courseForm.playlist_description[index]}
                                helperText={formErrors.playlist_description}
                              />
                              {/* <div className="account_txteditor_main descript_bottomzero">
                                <h6>Description</h6>

                                <div
                                  style={{
                                    // display: "grid",
                                    color: "#fff",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    placeholder=" "
                                    // name="playlist_description"
                                    value={
                                      courseForm.playlist_description[index]
                                    }
                                    onChange={(e) => handleChange(e, index)}
                                    onFocus={(e) => {
                                      setEditorType("playlist_description");
                                    }}
                                    style={{ height: "80px", color: "#fff" }}
                                  ></ReactQuill>
                                </div>
                              </div>
                              {formErrors.playlist_description && (
                                <div className="image_error">
                                  <p> {formErrors.playlist_description} </p>
                                </div>
                              )} */}
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Upload Image and Video
                              </FormHelperText>
                              <div className="post_image_upload upload_courseimg">
                                <div className="file_upload_product">
                                  <input
                                    // style={{ display: "none" }}
                                    id={`fileInput` + { index }}
                                    name="playlist_image"
                                    type="file"
                                    // inputProps={{ accept: "image/*" }}
                                    // inputProps={{ multiple: true }}
                                    onChange={(e) => {
                                      // console.log("index", index);
                                      handleChange(e, index);
                                    }}
                                    placeholder="browser"
                                  // multiple
                                  // ref={inputRef}
                                  />

                                  {/* <button onClick={handleClick}>Browse files</button> */}
                                </div>
                                {formErrors.playlist_image && (
                                  <div className="invalid-msg">
                                    {formErrors.playlist_image}
                                  </div>
                                  // <div className="image_error">
                                  //   <p> {formErrors.playlist_image} </p>
                                  // </div>
                                )}
                              </div>
                              {/* {console.log(
                          "courseForm.playlist_image[index]",
                          index,
                          courseForm.playlist_image[index]
                        )} */}
                              {console.log(
                                "courseForm.playlist_image[index]",
                                courseForm.playlist_image[index]
                              )}
                              {courseForm.playlist_image != undefined &&
                                courseForm.playlist_image[index] != undefined &&
                                courseForm.playlist_image[index].type ==
                                "video/mp4" ? (
                                <img
                                  src={"/images/upload-reel1.png"}
                                  alt=""
                                  style={{ height: "140px", width: "200px" }}
                                />
                              ) : courseForm.playlist_image[index] != "" ? (
                                <>
                                  {id ? (
                                    <img
                                      src={
                                        (courseForm.playlist_image[index]
                                          .name != undefined &&
                                          URL.createObjectURL(
                                            courseForm.playlist_image[index]
                                          )) ||
                                        courseForm.playlist_image[index]
                                      }
                                      alt=""
                                      style={{
                                        height: "120px",
                                        width: "200px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        courseForm.playlist_image[index]
                                      )}
                                      alt=""
                                      style={{
                                        height: "120px",
                                        width: "200px",
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="addmore_addbtn mb-2 ">
                          {courseForm.playlist_title.length !== 1 && (
                            <button
                              className="mr-2"
                              onClick={() => handleRemoveClick(index)}
                            >
                              Remove
                            </button>
                          )}
                          {courseForm.playlist_title.length - 1 === index && (
                            <button onClick={handleAddClick}>+ Add More</button>
                          )}
                        </div>
                      </>
                    );
                  })}

                  <div className="uplod_course">
                    <h4>Course Image</h4>
                  </div>
                  {/* ----------------------- course image ------------------------------------ */}
                  {/* ///////////////////////////////////////////////////////////////////////////////////// */}
                  <div className="row">
                    {courseForm.course_imgtitle?.map((value, index) => {
                      return (
                        <div className="col-lg-4 col-sm-12 new-all-inputs mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Course Title
                          </FormHelperText>
                          <div className="single_imagecourse">
                            <TextField
                              sx={{
                                width: "100%",
                              }}
                              name="course_imgtitle"
                              value={courseForm.course_imgtitle[index]}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                              className="input_username new-edit-main-all mb-3"
                              id="outlined-basic"
                              placeholder="Enter your course title"
                            />

                            <div className="post_image_upload upload_courseimg mt-1">
                              <div className="file_upload_product">
                                <input
                                  name="course_image"
                                  type="file"
                                  onChange={(e) => {
                                    handleChange(e, index);
                                  }}
                                  placeholder="browser"
                                />
                              </div>
                            </div>

                            {courseForm.course_image != undefined &&
                              courseForm.course_image[index] != undefined &&
                              courseForm.course_image[index].type ==
                              "video/mp4" ? (
                              <img
                                src={"/images/upload-reel1.png"}
                                alt=""
                                style={{ height: "140px", width: "200px" }}
                              />
                            ) : courseForm.course_image[index] != "" ? (
                              <>
                                {id ? (
                                  <img
                                    src={
                                      (courseForm.course_image[index].name !=
                                        undefined &&
                                        URL.createObjectURL(
                                          courseForm.course_image[index]
                                        )) ||
                                      courseForm.course_image[index]
                                    }
                                    alt=""
                                    style={{ height: "120px", width: "200px" }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(
                                      courseForm.course_image[index]
                                    )}
                                    alt=""
                                    style={{ height: "120px", width: "200px" }}
                                  />
                                )}
                              </>
                            ) : (
                              ""
                            )}

                            <div className="addmore_addbtn mb-2 ">
                              {courseForm.course_imgtitle.length !== 1 && (
                                <button
                                  className="mr-2 my-0"
                                  onClick={() => handleRemoveImageCourse(index)}
                                >
                                  Remove
                                </button>
                              )}
                              {courseForm.course_imgtitle.length - 1 ===
                                index && (
                                  <button
                                    className="my-0"
                                    onClick={handleAddCourseImage}
                                  >
                                    + Add More
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="product_button_main addcoursebtn">
                    <div className="add_product_btn">
                      {buttonloader == true ? (
                        <button type="button">
                          <SmallLoader
                            type="spinner-default"
                            bgColor={"#fff"}
                            color={"#fff"}
                            size={40}
                          />
                        </button>
                      ) : (
                        <>
                          {id ? (
                            <button
                              type="button"
                              onClick={(e) => handleupdate(e, id)}
                            >
                              Update Course
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => handleAddCourse(e)}
                            >
                              Add Course
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <FooterBig />
        </div>
      </div>
    </motion.div>
  );
};

export default UploadMycourse;
