import React, { useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

//
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import { NavLink } from "react-router-dom";
import { contracts, network, zeroAddress } from "../../utils/env";
import { polygon, polygonAmoy } from "viem/chains";
import { useAccount, useClient, useWalletClient } from "wagmi";
import { getContract, parseEther, publicActions } from "viem";
import { AssetContractShared, ERC20, Exchange } from "../../contracts";
import { errorMsg, successMsg } from "../../actions/customFn";
import NftSellModal from "../Modal/NftSellModal";
import ReactPlayer from "react-player";
import CustomAudioPlayer from "../AudioPlayerCard";
import { asyncPostPlay } from "../../actions/uploadBlogs";

const NftProductNew = (props) => {
  const {
    isLoader,
    product_list,
    handleLoadMore,
    hasMore,
    refresh,
    dataLength,
  } = props;
  const { address } = useAccount();
  const [loading, setLoading] = useState({});

  const [saledata, setSaleData] = useState({});
  const [showSaleModal, setShowSaleModal] = useState(false);
  // Buy order flow
  const getBuyerReplacementPattern = () => {
    return (
      window.web3.utils.padLeft("0x0", 8) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2)
    );
  };
  let { data: _walletClient } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;
  const client = useClient({
    chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
  });

  const matchOrder = async (sellerOrder, buyerOrder) => {
    const { request } = await walletClient.simulateContract({
      address: contracts.Exchange,
      abi: Exchange,
      functionName: "atomicMatch",
      value:
        sellerOrder.paymentToken === zeroAddress ? buyerOrder.basePrice : 0,
      args: [
        [
          buyerOrder.exchange,
          buyerOrder.maker,
          buyerOrder.taker,
          buyerOrder.feeRecipient,
          buyerOrder.target,
          buyerOrder.staticTarget,
          buyerOrder.paymentToken,
          sellerOrder.exchange,
          sellerOrder.maker,
          sellerOrder.taker,
          sellerOrder.feeRecipient,
          sellerOrder.target,
          sellerOrder.staticTarget,
          sellerOrder.paymentToken,
        ],
        [
          buyerOrder.makerRelayerFee,
          buyerOrder.takerRelayerFee,
          buyerOrder.makerProtocolFee,
          buyerOrder.takerProtocolFee,
          buyerOrder.basePrice,
          buyerOrder.extra,
          buyerOrder.maximumFill,
          buyerOrder.listingTime,
          buyerOrder.expirationTime,
          buyerOrder.salt,
          sellerOrder.makerRelayerFee,
          sellerOrder.takerRelayerFee,
          sellerOrder.makerProtocolFee,
          sellerOrder.takerProtocolFee,
          parseEther(sellerOrder.basePrice),
          sellerOrder.extra,
          sellerOrder.maximumFill,
          sellerOrder.listingTime,
          sellerOrder.expirationTime,
          sellerOrder.salt,
        ],
        [
          buyerOrder.feeMethod,
          buyerOrder.side,
          buyerOrder.saleKind,
          buyerOrder.howToCall,
          sellerOrder.feeMethod,
          sellerOrder.side,
          sellerOrder.saleKind,
          sellerOrder.howToCall,
        ],
        buyerOrder.calldata,
        sellerOrder.calldata,
        buyerOrder.replacementPattern,
        sellerOrder.replacementPattern,
        buyerOrder.staticExtradata,
        sellerOrder.staticExtraData,
        [sellerOrder.v, sellerOrder.v],
        [
          sellerOrder.r,
          sellerOrder.s,
          sellerOrder.r,
          sellerOrder.s,
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        ],
      ],
    });

    await walletClient.switchChain({
      id: network === "mainnet" ? polygon.id : polygonAmoy.id,
    });
    const transaction = await walletClient.waitForTransactionReceipt({
      hash: await walletClient.writeContract(request),
    });
    console.log(transaction, "transaction");
    return transaction;
  };
  const getCalldata = (address, abi, method, ...params) => {
    const contract = new window.web3.eth.Contract(abi, address);
    const calldata = contract.methods[method](...params).encodeABI();
    return calldata;
  };
  const handleBuy = async (buyOrder) => {
    setLoading((prev) => ({ ...prev, [itemId]: true }));
    const itemId = buyOrder.id;
    try {
      if (!address) {
        setLoading((prev) => ({ ...prev, [itemId]: false }));
        errorMsg("Please connect your wallet to make blockchain transaction");
        return;
      }
      const salt = window.web3.utils.hexToNumberString(
        window.web3.utils.randomHex(32)
      );

      const order = {
        exchange: buyOrder?.orders[0]?.exchange, // min_order.exchange
        maker: address,
        taker: buyOrder?.orders[0]?.maker, // Order taker address //
        makerRelayerFee: buyOrder?.orders[0]?.makerRelayerFee, // Maker relayer fee of the order, unused for taker order
        takerRelayerFee: buyOrder?.orders[0]?.takerRelayerFee, // Taker relayer fee of the order, or maximum taker fee for a taker order
        makerProtocolFee: buyOrder?.orders[0]?.makerProtocolFee, // Maker protocol fee of the order, unused for taker order
        takerProtocolFee: buyOrder?.orders[0]?.takerProtocolFee, // Taker protocol fee of the order, or maximum taker fee for a taker order
        feeRecipient: zeroAddress, // Order fee recipient or zero address for taker order
        feeMethod: buyOrder?.orders[0]?.feeMethod, // Fee method (protocol token or split fee)
        side: 0, // Side (buy/sell) // 0 bydefault
        saleKind: buyOrder?.orders[0]?.saleKind, // Kind of sale
        target: buyOrder?.orders[0]?.target, // Target
        howToCall: buyOrder?.orders[0]?.howToCall, // HowToCall
        calldata: getCalldata(
          contracts.AssetSharedContract,
          AssetContractShared,
          "safeTransferFrom",
          zeroAddress,
          address, // connectted wallet address
          buyOrder.onChainId, // need token onChainId
          1,
          buyOrder.uri
            ? window.ethers.toQuantity(window.ethers.toUtf8Bytes(buyOrder.uri))
            : []
        ), // Calldata
        maximumFill: 1,
        replacementPattern: getBuyerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
        staticTarget: buyOrder?.orders[0]?.staticTarget, // Static call target, zero-address for no static call
        staticExtradata: buyOrder?.orders[0]?.staticExtraData, // Static call extra data
        paymentToken: buyOrder?.orders[0]?.paymentToken, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
        basePrice: parseEther(buyOrder?.orders[0]?.basePrice), // Base price of the order (in paymentTokens)
        extra: buyOrder?.orders[0]?.extra, // m bid increment for English auctions, starting/ending price difference
        listingTime: Math.round(Date.now() / 1000) - 19800, // Listing timestamp
        expirationTime: 0, // Expiration timestamp - 0 for no expiry
        salt: salt, // Order salt, used to prevent duplicate hashes
      };
      console.log(order, "order");
      const ethers = window.ethers;

      const { v, r, s } = ethers.Signature.from(buyOrder?.orders[0]?.signature);
      console.log(v, r, s, "splidata");

      if (order.paymentToken !== zeroAddress) {
        // const paymentAddress = order.paymentToken;
        const paymentToken = getContract({
          address: order.paymentToken,
          abi: ERC20,
          client,
        });
        const allowance = await paymentToken.read.allowance([
          address,
          order.exchange,
        ]);
        console.log(allowance, "allowance");
        // eslint-disable-next-line no-undef
        console.log(BigInt(order.basePrice), "bigInt");
        // eslint-disable-next-line no-undef
        if (allowance < BigInt(order.basePrice)) {
          const { request } = await walletClient.simulateContract({
            address: paymentToken.address,
            abi: paymentToken.abi,
            functionName: "approve",
            args: [order.exchange, order.basePrice],
          });
          await walletClient.switchChain({
            id: network === "mainnet" ? polygon.id : polygonAmoy.id,
          });
          const transaction = await walletClient.waitForTransactionReceipt({
            hash: await walletClient.writeContract(request),
          });
          console.debug("Approval tx:", transaction);
        }
      }
      await matchOrder({ ...buyOrder?.orders[0], v, r, s }, order);
      successMsg("Sucessfully buy");
      setLoading((prev) => ({ ...prev, [itemId]: false }));
    } catch (error) {
      console.log(error);
      setLoading((prev) => ({ ...prev, [itemId]: false }));
    }
  };

  // Sell Order flow
  const handleSaleModalClose = () => setShowSaleModal(false);
  const handleSale = async (saleData) => {
    setSaleData(saleData);
    setShowSaleModal(true);
  };
  console.log("product_list", product_list);

  return (
    <>
      <div className=" ">
        {isLoader === true ? (
          <Loader />
        ) : product_list && Array.isArray(product_list) ? (
          <div className="nft_card_group" id="scrollableDiv">
            <>
              {product_list && Array.isArray(product_list) ? (
                <InfiniteScroll
                  dataLength={dataLength}
                  next={handleLoadMore}
                  hasMore={hasMore}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv"
                  refreshFunction={refresh}
                  endMessage={
                    <p
                      className="end-message-loader"
                      style={{ textAlign: "center" }}
                    >
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      Pull down to refresh
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
                  }
                >
                  <div className="row m-0">
                    {product_list.map(function (value, index) {
                      return (
                        <div
                          className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 "
                          key={index}
                        >
                          <div className="card_main_inner cursor-pointer">
                            <NavLink to={`/nft-details/${value.id}`}>
                              <div className="card_img">
                              {value.metadata.type === 2 ? (
                                <ReactPlayer
                                  url={`https://gateway.pinata.cloud/ipfs/${value?.metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  onPlay={()=>asyncPostPlay(value.id)}
                                  controls
                                  width="100%"
                                  height="auto"
                                />
                              ) : value.metadata.type === 1 ? (
                                <CustomAudioPlayer
                                  id={value.id}
                                  src={`https://gateway.pinata.cloud/ipfs/${value?.metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  backgroundImage={'/images/music-img.png'}
                                />
                              ) : (
                                <img src={`https://gateway.pinata.cloud/ipfs/${value?.metadata?.image?.replace(
                                  "ipfs://",
                                  ""
                                )}`} alt="" />
                              )}

                             
                              </div>
                            </NavLink>

                            <div className="card_main_inner_hedding">
                              <div className="left_side">
                                <span>{value?.metadata?.description}</span>
                                <p>{value?.metadata?.name}</p>
                              </div>
                              <div className="right_side">
                                {value?.orders?.some(
                                  (order) =>
                                    order.maker === address?.toLocaleLowerCase()
                                ) ? (
                                  <button onClick={() => handleSale(value)}>
                                    {loading[value.id] ? "Wait.." : "Sell Now"}
                                  </button>
                                ) : (
                                  <button onClick={() => handleBuy(value)}>
                                    {loading[value.id] ? "Wait.." : "Buy Now"}
                                  </button>
                                )}

                                <h4>
                                  <span>$</span> {value?.orders[0]?.basePrice}
                                </h4>
                              </div>
                            </div>
                            {/* <ul className="product-wishlist-sec">
                                        <li>{reactIcons.heartOutline}</li>
                                        <li>{reactIcons.add}</li>
                                      </ul> */}
                            <div className="overlay-image"></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              ) : (
                <RecordNotfound />
              )}
            </>
          </div>
        ) : (
          <h1 className="text-white"> Record Not Found </h1>
        )}
      </div>
      <NftSellModal
        show={showSaleModal}
        setShow={setShowSaleModal}
        data={saledata}
        close={handleSaleModalClose}
      />
    </>
  );
};

export default NftProductNew;
