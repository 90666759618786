import React, { useState } from "react";
import { FooterBig, FooterMobile, Header, RecordNotfound } from "../components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { motion } from "framer-motion";
import { errorMsg, images, successMsg } from "../actions/customFn";
import { useEffect } from "react";
import {
  asyncAddProductWishlist,
  getProductDetailsAll,
} from "../actions/productAction";
import ReactStars from "react-rating-stars-component";
import { getAddToCart } from "../actions/cartAction";
import Loader from "../hooks/Loader";

import ProductRatingModal from "../components/Modal/ProductRatingModal";

import ReactPlayer from "react-player";
import { reactIcons } from "../utils/icons";

const ProductDetails = () => {
  const [count, setCount] = useState(1);
  const [isAddCart, setIsCart] = useState(false);
  const [ProductDetails, setProductDetails] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [showimages, setShowImages] = useState({
    img: "",
    video: "",
  });
  const [NewRating, setNewRating] = useState(0);
  const [Show, setShow] = useState(false);
  const handleProductClose = () => setShow(false);
  const handleProductShow = () => setShow(true);

  const playerRef = React.useRef(null);

  const [addwishlist, setAddWishlist] = useState("");

  const objectStyle = {
    backgroundColor: "#ff0098",
  };
  const objectStyleValid = {
    backgroundColor: "#2d0066",
  };

  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const Showdata = (img, video) => {
    setShowImages({ img, video });
  };

  // Api Code And Function
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    setIsLoader(true);
    if (id != "") {
      getProductDetailsAll(id, setProductDetails, setIsLoader);
    }
  }, [id, NewRating]);

  const handlecourselike = async () => {
    try {
      getAddToCart(id, count, navigate, setIsCart);
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  // console.log("ProductDetails", ProductDetails);
  // ------------ add wishlist ----------
  const handleaddProductwishlist = async () => {
    try {
      asyncAddProductWishlist(id, { setAddWishlist, addwishlist });
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  const copyContent = async (text) => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      successMsg("Link copied to Clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg only_bg_color product-details-page">
        <div className="container-fluid">
          <Header />

          <div className="page-all-data">
            {isLoader == true ? (
              <Loader />
            ) : (
              <div className="">
                {ProductDetails !== "" ? (
                  <div className="campaignsdetail-main">
                    <div className="">
                      <div className="row ffff">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                              <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 product-details-left-img-main mb-3">
                                  {ProductDetails.product_image &&
                                    ProductDetails.product_image.length > 0 && (
                                      <>
                                        {showimages.video == undefined ||
                                          showimages.video == "" ? (
                                          <img
                                            src={
                                              showimages.img
                                                ? showimages.img
                                                : ProductDetails
                                                  .product_image[0].image
                                            }
                                            alt=""
                                          />
                                        ) : (
                                          <ReactPlayer
                                            url={showimages.video}
                                            playing={true}
                                            controls={true}
                                            loop={true}
                                            width="100%"
                                            height="500px"
                                            muted={true}
                                            light={
                                              <img
                                                src={
                                                  showimages.img
                                                    ? showimages.img
                                                    : "/images/courses_list_2.png"

                                                }
                                                alt=""
                                                style={{
                                                  width: "100%",
                                                  height: "500px",
                                                }}
                                              />
                                            }
                                            playsinline={true}
                                            ref={playerRef}
                                          // playIcon={<button>Play</button>}
                                          />
                                        )}
                                      </>
                                    )}

                                  { }
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 product-details-left-img d-none">
                              <div className="product-thum-main">
                                {ProductDetails.product_image != undefined &&
                                  ProductDetails.product_image.length > 0 &&
                                  ProductDetails.product_image.map(
                                    (value, index) => {
                                      return (
                                        <div
                                          className="product-thum-list product-details-left-img"
                                          key={index}
                                        >
                                          <img
                                            onClick={() =>
                                              Showdata(value.image, value.video)
                                            }
                                            src={value.image}
                                            alt=""
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                          <div className="product-details-main-right">
                            <div className="list_Breadcrumb mb-2">
                              <ul>
                                <li>
                                  <NavLink className="top_nav_link" to="/">
                                    {" "}
                                    Home <span>/</span>
                                  </NavLink>
                                </li>
                                <li>
                                  {" "}
                                  <NavLink
                                    className="top_nav_link"
                                    to="/product-listing"
                                  >
                                    Shop <span>/</span>
                                  </NavLink>
                                </li>
                                <li>
                                  {" "}
                                  <NavLink to="#">Product Details</NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="product-details-main-right-inner">
                            <h1 className="heding-super-mario">
                              {ProductDetails.name}
                            </h1>
                            <p className="product-condition">
                              Condition: <strong>Like New</strong>
                            </p>
                            <p className="product-time">
                              Time left: 19h 16m Wednesday, 10:59
                            </p>
                            {/* <p className="heding-toy">
                              {ProductDetails.category_name}
                            </p> */}

                            {/* <div className="product_pricewishbtndiv">
                              <div className="product-details-main-right-reviews">
                                <span>
                                  Reviews <p>:</p>
                                </span>
                                {ProductDetails.avg_product_ratings !=
                                  undefined &&
                                  ProductDetails.avg_product_ratings != "" && (
                                    <div className="prd_star_rating ">
                                      <ul>
                                        <li>
                                          <FontAwesomeIcon icon={faStar} />
                                        </li>
                                        <li className="star_point">
                                          {ProductDetails.avg_product_ratings}
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                              </div>

                              {ProductDetails.delivery_time != undefined &&
                                ProductDetails.delivery_time != "" && (
                                  <div className="product-details-main-right-reviews ml-4">
                                    <span>
                                      Delivery Time <p>:</p>
                                    </span>

                                    <div className="prd_star_rating ml-2">
                                      {ProductDetails.delivery_time}
                                    </div>
                                  </div>
                                )}
                            </div> */}

                            <div className="product_pricewishbtndiv">
                              <div className="product-details-main-right-price">
                                <span>
                                  Current bid<p>:</p>
                                </span>

                                <h1>
                                  $ <h2>{ProductDetails.price}</h2>{" "}
                                </h1>
                              </div>

                              <div className="product_sharewishlistbutn">
                                <div className="card_Buy_now">
                                  {//  ProductDetails.is_cart == false
                                    // console.log("ProductDetails.is_cart=",ProductDetails.is_cart)
                                    ProductDetails.is_cart != undefined &&
                                      ProductDetails.is_cart == false &&
                                      isAddCart == false ? (
                                      <button onClick={() => handlecourselike()}>
                                        Add to cart
                                      </button>
                                    ) : (
                                      <button className="alredy_incartbtn">
                                        Already In Cart
                                      </button>
                                    )}
                                </div>
                                <div className="product-details-btn-inc-dec">
                                  <button onClick={decrement}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="3"
                                      viewBox="0 0 15 3"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15 3H0V0H15V3Z"
                                        fill="#ffffff"
                                      />
                                    </svg>
                                  </button>
                                  <h1>
                                    {ProductDetails.is_cart == true
                                      ? ProductDetails.is_cart_quantity
                                      : count}
                                  </h1>
                                  <button
                                    onClick={increment}
                                    className="product-btn-dec"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15 9H0V6H15V9Z"
                                        fill="#ffffff"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M6 15L6 1.31134e-07L9 0L9 15L6 15Z"
                                        fill="#ffffff"
                                      />
                                    </svg>
                                  </button>
                                </div>
                                {/* <button
                                  className="addwishbtn"
                                  type="button"
                                  onClick={() => {
                                    handleaddProductwishlist();
                                  }}
                                  style={
                                    addwishlist == true ||
                                    ProductDetails.product_wishlist == true
                                      ? objectStyleValid
                                      : objectStyle
                                  }
                                >
                                  {addwishlist == true ||
                                  ProductDetails.product_wishlist == true
                                    ? "Aleready Added"
                                    : "Add to WishList"}
                                </button> */}
                                <button
                                  type="button"
                                  className={`wish-list-button ${addwishlist === true ||
                                    ProductDetails.product_wishlist === true
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    handleaddProductwishlist();
                                  }}
                                >
                                  {" "}
                                  {reactIcons.heart}
                                </button>
                                {/* <button
                                  type="button"
                                  className="product_sharebtn"
                                  onClick={() => {
                                    copyContent();
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={"/images/share_post.png"}
                                    alt=""
                                  />{" "}
                                </button> */}
                              </div>
                            </div>

                            <ul className="product-desc">
                              <li>
                                Postage: $3.50 Economy Delivery. See details
                              </li>
                              <li>
                                Delivery: Estimated between Sat, 17 Feb and Tue,
                                20 feb to PE210AA
                              </li>
                              <li>
                                Delivery: Estimated between Sat, 17 Feb and Tue,
                                20 feb to PE210AA
                              </li>
                              <li>Returns: No retrns accepted. See details</li>
                              <li>
                                Payment:{" "}
                                <img
                                  src={"/images/payment-accept.png"}
                                  alt={""}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                          <div className="pd-right-card">
                            <h3 className="heading">Shop with confidence</h3>
                            <h5 className="heading-two ">
                              eBay Money Back Guarntee
                            </h5>
                            <p className="desc">
                              Get the item you ordered or your money back.
                            </p>
                          </div>
                          <div className="pd-right-card">
                            <h3 className="heading">Seller information</h3>
                            <h5 className="heading-two text-blue">
                              martinwinter (22)
                            </h5>
                            <p className="desc">100% positive Feedback</p>

                            <div className="middle-sec">
                              <div className="text-blue save-later">
                                <span className="heart-icon">
                                  {reactIcons.heart}
                                </span>
                                <span className="underline">Save seller</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <span className="text-blue">
                                    Contact seller
                                  </span>
                                </div>
                                <div>
                                  <span className="text-blue">
                                    See other items
                                  </span>
                                </div>
                              </div>
                            </div>
                            <p className="desc">
                              Registers as private seller, so consumer rights
                              stemming from EU consumer protection law do not
                              apply. eBay Monet Back guarantee still applies to
                              most purchases.{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="product-details-sction2">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            {ProductDetails.description != undefined &&
                              ProductDetails.description &&
                              ProductDetails.description.replace(
                                /(<([^>]+)>)/gi,
                                ""
                              ) && (
                                <div className="product-details-sction2-inner">
                                  <h1>Description</h1>
                                  <hr className="product-details-sction2_line" />
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: ProductDetails.description,
                                    }}
                                  ></p>
                                </div>
                              )}
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            {ProductDetails.product_specifications !=
                              undefined &&
                              ProductDetails.product_specifications.length >
                              0 && (
                                <div className="product-details-sction2-inner">
                                  <h1>Specification</h1>
                                  <hr className="product-details-sction2_line" />
                                  <div className="description_section_inner">
                                    <div className="blog_details_text">
                                      <ul className="custom-list_blog">
                                        {ProductDetails.product_specifications !=
                                          undefined &&
                                          ProductDetails.product_specifications
                                            .length > 0 &&
                                          ProductDetails.product_specifications.map(
                                            (value, index) => {
                                              return (
                                                <li key={index}>
                                                  {value.title}
                                                </li>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="product-det-Reviews-rating ">
                        <div className="product-Reviews-rating-heading">
                          <div className="d-flex justify-content-between">
                            <h1>
                              {ProductDetails.ratings_reviews != undefined &&
                                ProductDetails.ratings_reviews.length > 0 &&
                                "Reviews and rating"}{" "}
                            </h1>

                            <div className="Product_review_btn">
                              {ProductDetails.is_buy != undefined &&
                                ProductDetails.is_buy == true ? (
                                <button
                                  onClick={() => {
                                    handleProductShow();
                                  }}
                                  className="course_Feedback_btn2"
                                >
                                  Product Review
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        {ProductDetails.ratings_reviews != undefined &&
                          ProductDetails.ratings_reviews.map((value, index) => {
                            return (
                              <div className="product-Reviews-rating-card">
                                <div>
                                  <div className="product-Revi-rating-profile">
                                    <div className="latestblogs_right_profile1_left">
                                      <div className="right_profile_left_bg">
                                        <img src={value.image} alt="" />
                                      </div>
                                      <div className="right-section-profile-name">
                                        <span>{value.name}</span>
                                        <p className="desc">
                                          100% Positive Feedback
                                        </p>
                                        <p className="date">
                                          {reactIcons.calender} {value.date}
                                        </p>
                                      </div>
                                    </div>

                                    {/* <div className="prd_star_rating ">
                                    <ul>
                                      <li>
                                        <ReactStars
                                          size={24}
                                          value={value.rating}
                                          isHalf={true}
                                          emptyIcon={
                                            <i className="far fa-star"></i>
                                          }
                                          halfIcon={
                                            <i className="fa fa-star-half-alt"></i>
                                          }
                                          fullIcon={
                                            <i className="fa fa-star"></i>
                                          }
                                          activeColor="#fc5301"
                                        />
                                      </li>
                                      <li className="star_point">
                                        {value.rating}
                                      </li>
                                    </ul>
                                  </div> */}
                                  </div>
                                  <h2
                                    dangerouslySetInnerHTML={{
                                      __html: value.reviews,
                                    }}
                                  ></h2>
                                </div>
                                <div className="btn-group-vertical">
                                  <button type="button" className="btn-fill">
                                    Seller's Other items
                                  </button>
                                  <button type="button" className="btn-outline">
                                    Contact
                                  </button>
                                  <button type="button" className="btn-outline">
                                    <span>{reactIcons.heart}</span> Save seller
                                  </button>
                                </div>
                              </div>
                            );
                          })}

                        <div className="top_courses_heading">
                          {ProductDetails.faq != undefined &&
                            ProductDetails.faq.length > 0 && <h1>FAQs</h1>}

                          <div className="askedquestion_section">
                            {ProductDetails.faq != undefined &&
                              ProductDetails.faq.map((value, index) => {
                                return (
                                  <div className="course_questionlist">
                                    <h5 className="product_answerflex mb-2">
                                      Que. {index + 1} :
                                      <span> {value.question} </span>
                                    </h5>
                                    <p className="product_answerflex">
                                      {" "}
                                      Answer :{" "}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: value.answer,
                                        }}
                                      ></span>{" "}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="friends-record">
                    <RecordNotfound />
                  </div>
                )}

                <FooterBig />
                <div className="mobile-footer-new">
                  <FooterMobile />
                </div>
              </div>
            )}

            <ProductRatingModal
              show={Show}
              close={handleProductClose}
              NewRatingList={setNewRating}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default ProductDetails;
