import React, { useRef, useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import './AudioPlayerCard.css';
import { asyncPostPlay } from '../../actions/uploadBlogs';

const CustomAudioPlayer = ({ src, backgroundImage, id }) => {
    const audioPlayerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        if (audioPlayerRef.current) {
            // Pause other instances
            const players = document.querySelectorAll('audio');
            players.forEach(player => {
                if (player !== audioPlayerRef.current.audio.current) {
                    player.pause();
                }
            });

            setIsPlaying(true);
            if (id) {
                asyncPostPlay(id); // Invoke the play count callback function
            }
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    return (
        <div className="audio-player-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <AudioPlayer
                ref={audioPlayerRef}
                src={src}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handlePause}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                showJumpControls={false}
                layout="horizontal-reverse"
                className="custom-audio-player"
            />
        </div>
    );
};

export default CustomAudioPlayer;
