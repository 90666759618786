import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  ChannelList,
  // VirtualizedMessageList,
  Attachment,
} from "stream-chat-react";
import { useChecklist } from "../components/chat_components/ChecklistTasks";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "@stream-io/stream-chat-css/dist/css/index.css";
// import './App.css';

// import Button from "react-bootstrap/Button";
// import Offcanvas from "react-bootstrap/Offcanvas";

import {
  CreateChannel,
  CustomMessage,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThreadHeader,
} from "../components/chat_components";

// import { getRandomImage } from "../assets";
import { ChannelInner } from "../components/chat_components/ChannelInner/ChannelInner";
import { useDispatch, useSelector } from "react-redux";
import CreateGroup from "../components/chat_components/CreateGroup/CreateGroup";
import { icons } from "../actions/customFn";
import { setIsLogout } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

// const urlParams = new URLSearchParams(window.location.search);

// const noChannelNameFilter = urlParams.get("no_channel_name_filter") || false;
// const skipNameImageSet = urlParams.get("skip_name_image_set") || false;

// const filters = noChannelNameFilter
//   ? { type: "messaging", members: { $in: [user] } }
//   : { type: "messaging", name: "Social Demo", demo: "social" };

const options = { state: true, watch: true, presence: true, limit: 8 };

const sort = {
  last_message_at: -1,
  updated_at: -1,
};

export const GiphyContext = React.createContext({});

const Chat2 = () => {
  const { userInfo, settingData } = useSelector((state) => state.userAuth);
  const [userToConnect, setuserToConnect] = useState("");
  const [chatClient, setChatClient] = useState(null);
  const [giphyState, setGiphyState] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [isActiveTab, setIsActiveTab] = useState("0");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const apiKey                             = "e4c9482xh76z" || process.env.REACT_APP_STREAM_KEY;

  const targetOrigin = "http://localhost:3000/chat";
  useChecklist(chatClient, targetOrigin);

  useEffect(() => {
    if (userInfo != null && userInfo.user_id !== undefined) {
      const userToken = userInfo.chat_stream_token;
      setuserToConnect({
        id: userInfo.user_id.toString(),
        name: userInfo.username,
        image: userInfo.image,
      });

      const initChat = async () => {
        const client = StreamChat.getInstance(settingData.stream_chat_api_key, {
          enableInsights: true,
          enableWSFallback: true,
        });
        await client.connectUser(
          {
            id: userInfo.user_id.toString(),
            name: userInfo.username,
            image: userInfo.image,
          },
          userToken
        );
        setChatClient(client);
      };

      if (settingData.stream_chat_api_key != undefined) {
        initChat();
      }

      return () => chatClient?.disconnectUser();
    }
  }, [userInfo, settingData]); // eslint-disable-line

  //   if (skipNameImageSet) {
  //     delete userToConnect.name;
  //     delete userToConnect.image;
  //   }

  useEffect(() => {
    const handleThemeChange = ({ data, origin }) => {
      // handle events only from trusted origin
      if (origin === targetOrigin) {
        if (data === "light" || data === "dark") {
          setTheme(data);
        }
      }
    };

    window.addEventListener("message", handleThemeChange);
    return () => window.removeEventListener("message", handleThemeChange);
  }, []);

  useEffect(() => {
    const mobileChannelList = document.querySelector("#mobile-channel-list");
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add("show");
      document.body.style.overflow = "hidden";
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove("show");
      document.body.style.overflow = "auto";
    }
  }, [isMobileNavVisible]);

  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    setAppHeight();
    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const toggleMobile = () => setMobileNav(!isMobileNavVisible);
  // const handleClose = () => setMobileNav(false);

  const giphyContextValue = { giphyState, setGiphyState };

  if (!chatClient) return null;

  const CustomGallery: React.FC<GalleryProps> = (props) => {
    const { images } = props;

    const updatedImages: ReactImageGalleryItem[] = [];

    Object.values(images).forEach((image) => {
      if (image.image_url) {
        updatedImages.push({ original: image.image_url });
      }
    });

    return <ImageGallery items={updatedImages} />;
  };

  const CustomAttachment: React.FC<AttachmentProps> = (props) => {
    return <Attachment {...props} Gallery={CustomGallery} />;
  };
  const handleTabClick = (e) => {
    setIsActiveTab(e);
  };
  const loggedOut = () => {
    dispatch(setIsLogout());
    navigate("/login");
  };

  return (
    <Chat
      client={chatClient}
      theme={`messaging ${theme}`}
    // customClasses={customClasses}
    >
      <div id="mobile-channel-list">
        <div className="chat-tabs">
          <div className="chat-tabs-group">
            <ul>
              <li>
                <button onClick={() => handleTabClick("0")}>Chats</button>
                {isActiveTab === "0" && <hr className="border-botoom" />}
              </li>
              <li>
                <button onClick={() => handleTabClick("1")}>Group Chats</button>
                {isActiveTab === "1" && <hr className="border-botoom" />}
              </li>
              <li>
                <button onClick={() => handleTabClick("2")}>Contacts</button>
                {isActiveTab === "2" && <hr className="border-botoom" />}
              </li>
            </ul>
            <ul>
              <li>
                <button onClick={() => loggedOut()}>
                  <img src={"/icons/logout.png"} alt="logout" />
                </button>
              </li>
            </ul>
          </div>
          {isActiveTab === "0" && (
            <ChannelList
              showChannelSearch
              additionalChannelSearchProps={{ searchForChannels: true }}
              filters={{
                type: "messaging",
                members: { $in: [userToConnect.id.toString()] },
              }}
              sort={sort}
              options={options}
              List={(props) => (
                <MessagingChannelList
                  {...props}
                  onCreateChannel={() => setIsCreating(!isCreating)}
                />
              )}
              Preview={(props) => (
                <>
                  <MessagingChannelPreview
                    {...props}
                    {...{ setIsCreating, chatClient }}
                  />
                </>
              )}
            />
          )}
          {isActiveTab === "1" && (
            <ChannelList
              showChannelSearch
              additionalChannelSearchProps={{ searchForChannels: true }}
              filters={{
                type: "messaging",
                members: { $in: [userToConnect.id.toString()] },
              }}
              sort={sort}
              options={options}
              List={(props) => (
                <MessagingChannelList
                  {...props}
                  onCreateChannel={() => setIsCreating(!isCreating)}
                />
              )}
              Preview={(props) => (
                <>
                  <MessagingChannelPreview
                    {...props}
                    {...{ setIsCreating, chatClient }}
                  />
                </>
              )}
            />
          )}
          {isActiveTab === "2" && (
            <ChannelList
              showChannelSearch
              additionalChannelSearchProps={{ searchForChannels: true }}
              filters={{
                type: "messaging",
                members: { $in: [userToConnect.id.toString()] },
              }}
              sort={sort}
              options={options}
              List={(props) => (
                <MessagingChannelList
                  {...props}
                  onCreateChannel={() => setIsCreating(!isCreating)}
                />
              )}
              Preview={(props) => (
                <>
                  <MessagingChannelPreview
                    {...props}
                    {...{ setIsCreating, chatClient }}
                  />
                </>
              )}
            />
          )}
        </div>
      </div>
      <div>
        <Channel
          Attachment={CustomAttachment}
          Input={MessagingInput}
          maxNumberOfFiles={10}
          Message={CustomMessage}
          multipleUploads={true}
          ThreadHeader={MessagingThreadHeader}
          TypingIndicator={() => null}
        >
          {/* {isCreating && (
            <CreateChannel
              toggleMobile={toggleMobile}
              onClose={() => setIsCreating(false)}
            />
          )} */}
          <CreateGroup
            show={isCreating}
            setShow={setIsCreating}
            close={() => setIsCreating(false)}
          />
          <GiphyContext.Provider value={giphyContextValue}>
            {/* old one */}
            {/* <ChannelInner theme={theme} toggleMobile={toggleMobile} /> */}
            {/* new one */}
            <ChannelInner theme={theme} />
          </GiphyContext.Provider>
        </Channel>
      </div>
    </Chat>
  );
};

export default Chat2;
