import React from "react";

import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import { asyncPostPlay } from "../../actions/uploadBlogs";
import CustomAudioPlayer from "../AudioPlayerCard";

const ViewPostModal = (props) => {
  const { data } = props;

  return (
    <Modal show={props.show} className="view_post_modal" onHide={props.close}>
      {/* <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <h4>Post Upload</h4>
      </Modal.Header> */}
      <div className="vpm_parent">
        <div className="vpm_post">
          {data.media_type === "video" ? (
            <ReactPlayer url={data.video}
            onPlay={()=>asyncPostPlay(data.id)}
            
            controls width="100%" height="auto" />
          ): data.media_type === "audio" ? (
            <CustomAudioPlayer
              id={data.id}
              src={data.audio}
              backgroundImage={data.thumbnail}
            />
          ) : 
          
          (
            <img src={data.image} alt="" className="my_post_img" />
          )}
        </div>
        <div className="vpm_text_sec">
          <div className="pro-sec">
            <img src={data.author_image} alt="" />
            <h6>{data.author_name}</h6>
          </div>
          <h2>{data.title}</h2>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            ></span>{" "}
          </p>
          <div className="vmp_close_btn">
            <button onClick={props.close}>Close </button>
          </div>
        </div>
        {/* <div className="myself_post">
          <div className="pro-sec">
            <img src={data.author_image} alt="" />
            <h6>{data.author_name}</h6>
          </div>
          <h2>{data.title}</h2>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            ></span>{" "}
          </p>
        </div> */}
      </div>

      {/* <div className="uploadpost_cont new-all-inputs">
        <div className="upload_btn_last">
          <button onClick={props.close}>Close </button>
        </div>
      </div> */}
    </Modal>
  );
};

export default ViewPostModal;
