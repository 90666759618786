import React, { useState } from "react";
import ReactPlayer from "react-player";
import { asyncCoursePlayListComplete } from "../../actions/courseAction";
import { asyncPostPlay } from "../../actions/uploadBlogs";

const CourseDetailVideo = (props) => {
  const { playlist, courseId } = props;

  const [PlayListID, setPlayListID] = useState([]);

  const handelPlayListComplete = ({ courseId, playlistId }) => {
    asyncCoursePlayListComplete(courseId, playlistId, setPlayListID);
    // setPlayListID((state)=>([...state,playlistId]));
  };

  console.log("playlist.media_type", playlist)
  return (
    <>
      <div className="course_video_section">
        <div className="course_details_video">
          {playlist.midea_type == "image" ? (
            <div
              className="courses_detail_banner new_banner_image"
              style={{
                backgroundImage: `url(${playlist.image})`,
              }}
            ></div>
          ) : (
            <ReactPlayer
              url={playlist.video}
              playing={true}
              onPlay={()=>asyncPostPlay(playlist.id)}
              controls={true}
              loop={true}
              width="100%"
              height="100%"
              muted={true}
              playIcon={
                <button className="play_circle">
                  {" "}
                  <img src={"images/play-circle.png"} alt="" />
                </button>
              }
              light={
                <img
                  src={playlist.image}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              }
              playsinline={true}
            />
          )}
        </div>

        <div className="couse_details_videocont">
          <button>Chapter : {playlist.chapter}</button>
          <h1>{playlist.playlist_title}</h1>
          {playlist.playlist_description != undefined ? (
            <p
              dangerouslySetInnerHTML={{
                __html: playlist.playlist_description,
              }}
            ></p>
          ) : (
            ""
          )}
          <div className="chapter_button_main">
            <div className="complete_chapter_btn">
              {console.log(
                " PlayListID.includes(playlist.playlist_id)",
                PlayListID.includes(playlist.playlist_id)
              )}
              {PlayListID.includes(playlist.playlist_id) ||
                playlist.is_complete ? (
                <button>Completed </button>
              ) : (
                <button
                  onClick={() =>
                    handelPlayListComplete({
                      courseId,
                      playlistId: playlist.playlist_id,
                    })
                  }
                >
                  Complete Chapter
                </button>
              )}
            </div>
            <div className="course_download_btn">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M12.3077 14.5C12.3077 14.3194 12.2468 14.1632 12.125 14.0313C12.0032 13.8993 11.859 13.8333 11.6923 13.8333C11.5256 13.8333 11.3814 13.8993 11.2596 14.0313C11.1378 14.1632 11.0769 14.3194 11.0769 14.5C11.0769 14.6806 11.1378 14.8368 11.2596 14.9688C11.3814 15.1007 11.5256 15.1667 11.6923 15.1667C11.859 15.1667 12.0032 15.1007 12.125 14.9688C12.2468 14.8368 12.3077 14.6806 12.3077 14.5ZM14.7692 14.5C14.7692 14.3194 14.7083 14.1632 14.5865 14.0313C14.4647 13.8993 14.3205 13.8333 14.1538 13.8333C13.9872 13.8333 13.8429 13.8993 13.7212 14.0313C13.5994 14.1632 13.5385 14.3194 13.5385 14.5C13.5385 14.6806 13.5994 14.8368 13.7212 14.9688C13.8429 15.1007 13.9872 15.1667 14.1538 15.1667C14.3205 15.1667 14.4647 15.1007 14.5865 14.9688C14.7083 14.8368 14.7692 14.6806 14.7692 14.5ZM16 12.1667V15.5C16 15.7778 15.9103 16.0139 15.7308 16.2083C15.5513 16.4028 15.3333 16.5 15.0769 16.5H0.923077C0.666667 16.5 0.448718 16.4028 0.269231 16.2083C0.0897436 16.0139 0 15.7778 0 15.5V12.1667C0 11.8889 0.0897436 11.6528 0.269231 11.4583C0.448718 11.2639 0.666667 11.1667 0.923077 11.1667H5.39423L6.69231 12.5833C7.0641 12.9722 7.5 13.1667 8 13.1667C8.5 13.1667 8.9359 12.9722 9.30769 12.5833L10.6154 11.1667H15.0769C15.3333 11.1667 15.5513 11.2639 15.7308 11.4583C15.9103 11.6528 16 11.8889 16 12.1667ZM12.875 6.23958C12.984 6.52431 12.9391 6.76736 12.7404 6.96875L8.43269 11.6354C8.31731 11.7674 8.17308 11.8333 8 11.8333C7.82692 11.8333 7.68269 11.7674 7.56731 11.6354L3.25962 6.96875C3.0609 6.76736 3.01603 6.52431 3.125 6.23958C3.23397 5.96875 3.42308 5.83333 3.69231 5.83333H6.15385V1.16667C6.15385 0.986111 6.21474 0.829861 6.33654 0.697917C6.45833 0.565972 6.60256 0.5 6.76923 0.5H9.23077C9.39744 0.5 9.54167 0.565972 9.66346 0.697917C9.78526 0.829861 9.84615 0.986111 9.84615 1.16667V5.83333H12.3077C12.5769 5.83333 12.766 5.96875 12.875 6.23958Z"
                    fill="white"
                  />
                </svg>{" "}
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseDetailVideo;
