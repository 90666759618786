import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FooterBig, Header } from "../components";
import axios from "../axios";
import { useParams } from "react-router-dom";
import Loader from "../hooks/Loader";
import { reactIcons } from "../utils/icons";
import { Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import AudioPlayer from "../components/AudioPlayerCard/index";
import InfiniteScroll from "react-infinite-scroll-component";
import { asyncPostPlay } from "../actions/uploadBlogs";

const AllPosts = () => {
  const { type } = useParams();
  const [posts, setPosts] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const getAlbumPost = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
        offset: currentPage,
      };
      let str =
        type == "music"
          ? "/get-most-view-music"
          : type == "video"
          ? "/get-most-view-video"
          : "/get-featured-blogs";
      const response = await axios.post(str, params);
      if (response.data.status) {
        if (response.data.data.length > 0) {
          setPosts((prevState) => [...prevState, ...response.data.data]);
          setHasMore(true);
          setIsLoader(false);
        } else {
          setHasMore(false);
          setIsLoader(false);
        }
      } else {
        setHasMore(false);
        setPosts([]);
        setIsLoader(false);
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            //   errorMsg("Somthing went wrong...");
            setIsLoader(false);
            setHasMore(false);
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setIsLoader(false);
            setHasMore(false);
          }
        }
      }
    }
  };
  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
      setHasMore(false);
    }, 2000);
  };

  useEffect(() => {
    if (type) {
      getAlbumPost();
    }
  }, [type, currentPage]);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg ">
        <div className="container-fluid">
          <Header />
          {isLoader == true ? (
            <Loader />
          ) : (
            <div className="page-all-data">
              <div className="add-product-main">
                <div className="add_product_textfild ">
                  <div className="row">
                    <div className="page-container tab_showdata ">
                      <div className="banner">
                        {/* <img
                          src={''}
                          alt="Banner"
                          className="banner-image"
                        /> */}
                      </div>
                      <h1 className="page-title">
                        {type == "music"
                          ? "SONG OF THE WEEK"
                          : type == "video"
                          ? "Video Posts"
                          : "Featured Posts"}
                      </h1>

                      <div className="music-slider" id="scrollableDiv">
                        <InfiniteScroll
                          dataLength={posts.length}
                          next={handleLoadMore}
                          hasMore={hasMore}
                          scrollableTarget="scrollableDiv"
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          <div className="row">
                            {posts.length > 0 ? (
                              posts.map((value, index) => (
                                <div className="col-md-3 mt-2">
                                  <div
                                    key={index}
                                    className="music-slider-card trending-post"
                                  >
                                    <div className="d-flex justify-content-between align-items-center gap-2 mb-2 tp-heading">
                                      <div className="d-flex align-items-center gap-10">
                                        <img
                                          src={value.author_image}
                                          className="rounded-circle border border-white"
                                          width={40}
                                          height={40}
                                          alt=""
                                        />
                                        <h6 className="profile-name-heading flex-1 text-16 capitalize font-poppins font-medium truncate popins">
                                          {value.author_name}
                                        </h6>
                                      </div>
                                      <span className="text-14 text-gray-300 popins font-500">
                                        1d
                                      </span>
                                    </div>
                                    <div className="w-100">
                                      <div className="">
                                        <p>{value.title}</p>

                                        {value.media_type === "video" ? (
                                          <ReactPlayer
                                            url={value.video}
                                  onPlay={()=>asyncPostPlay(value.id)}

                                            controls
                                            width="100%"
                                            height="235px"
                                          />
                                        ) : value.media_type === "audio" ? (
                                          <AudioPlayer
                                          id={value.id}
                                            src={value.audio}
                                            backgroundImage={value.thumbnail}
                                          />
                                        ) : (
                                          <img
                                            src={value.image}
                                            className="song-img"
                                            alt=""
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                      <div className="d-flex flex-1 align-items-center gap-10">
                                        <div className="d-flex align-items-center gap-5">
                                          <span className="text-gray-300 text-20">
                                            {reactIcons.circleUp}
                                          </span>
                                          <span className="text-gray-300 font-poppins text-14 font-medium">
                                            0
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-5">
                                          <span className="text-gray-300 text-20">
                                            {reactIcons.repeat}
                                          </span>
                                          <span className="text-gray-300 font-poppins text-14 font-medium">
                                            0
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-5">
                                          <span className="text-gray-300 text-20">
                                            {reactIcons.message}
                                          </span>
                                          <span className="text-gray-300 font-poppins text-14 font-medium">
                                            0
                                          </span>
                                        </div>
                                      </div>
                                      <Button
                                        variant="dark"
                                        className="tp-mint"
                                      >
                                        Mint 0
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <h2>Record Not Found</h2>
                            )}
                          </div>
                        </InfiniteScroll>
                        {/* </OwlCarousel> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <FooterBig />
        </div>
      </div>
    </motion.div>
  );
};

export default AllPosts;
